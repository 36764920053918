#approvals .line{
    border-bottom: 3px solid #8bc349;
     margin-bottom: 2rem;
}

#approvals img {
    margin: 0px 0px 8px 5px;
}
#approvals a {
    padding: unset;
}
#approvals a:hover {
    padding: unset;
    border-bottom: 2px solid;
}