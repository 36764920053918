.modal-header {
  border-radius: calc(.3rem - 1px)!important;
  /* border-top-right-radius: calc(.3rem - 1px); */
}
.close {
  font-size: 2.5rem;
}
.product-slider {
    background: white;
    -webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
            box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.125);
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .product-slider::before, .product-slider::after {
    background: -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: auto;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .product-slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }
  .product-slider::before {
    left: 0;
    top: 0;
  }
  .product-slider .product-slide-track {
    -webkit-animation: product-scroll 40s linear infinite;
            animation: product-scroll 40s linear infinite;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(250px * 14);
  }
  .product-slider .slide {
    margin: 0rem 3rem;
    height: auto;
    width: 250px;
  }
  .product-slider .product-slide-track:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
  #productslide .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0px; 
    color: black;
    background-color: white;
}
#productslide .card {
  background-color: white;
  border: none;
}
#productslide .card-text {
    padding: 1.25rem;
    min-height: 100px;
}
#productslide .button {
    width: 100% !important;
}
  
  @-webkit-keyframes product-scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 10));
              transform: translateX(calc(-250px * 10));
    }
  }
  
  @keyframes product-scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 10));
              transform: translateX(calc(-250px * 10));
    }
  }
  