
.timeline {
	position: relative;
}
.timeline::before {
	content: "";
	background: #093764;
	width: 4px;
	height: 96%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.timeline-item {
	width: 100%;
	margin-bottom: 0px;
}
.timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
	padding: 60px 0px 10px 0px;
}
.timeline-item:nth-child(odd) .timeline-content .date {
	right: auto;
	left: 0;
}
.timeline-item:nth-child(odd) .timeline-content::after {
	content: "";
	position: absolute;
	border-style: solid;
	width: 0;
	height: 0;
	top: 30px;
	left: -15px;
	border-width: 10px 15px 10px 0;
	border-color: transparent;
}
.timeline-item::after {
	content: "";
	display: block;
	clear: both;
}

.timeline-content {
    text-align: right;
	position: relative;
	width: 45%;
	padding: 60px 0px 0px;
	border-radius: 4px;
}

.timeline-img {
	width: 30px;
	height: 30px;
	background: #093764;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	margin-top: 0px;
	margin-left: -15px;
}



.date {
	background: #093764;
	display: inline-block;
	color: #ffffff;
	padding: 10px;
	position: absolute;
	top: 0;
	right: 0;
}

@media screen and (max-width: 768px) {
	.timeline::before {
		left: 50px;
	}
	.timeline .timeline-img {
		left: 50px;
	}
	.timeline .timeline-content {
		max-width: 100%;
		width: auto;
        margin-left: 70px;
        text-align: left;
	}
	.timeline .timeline-item:nth-child(odd) .timeline-content {
		float: none;
	}
	.timeline .timeline-item:nth-child(even) .timeline-content::after {
		content: "";
		position: absolute;
		border-style: solid;
		width: 0;
		height: 0;
		top: 30px;
		left: -15px;
		border-width: 10px 15px 10px 0;
		border-color: transparent;
    }
    .date {
        right: unset;
    }
}