.card{
    border-radius: 0px;
    background-color: #093764;
}

#currentopeningnav a {
    color: white !important;
}
#currentopeningnav a:hover {
    color: #ffffffb3 !important;
}
#currentopeningnav .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: transparent;
    background:url('../assets/img/rightarrow.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
}

#currentopening .card-img-overlay {
    background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

