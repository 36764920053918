.carousel-caption {
    background: white;
    position: absolute;
    right: 0%;
    bottom: 20px;
    left: 65%;
    z-index: 10;
    padding: 20px;
    /* padding-bottom: 20px; */
    color: black;
    text-align: left;
}

#slide .btn {
    background: none !important;
    border-color: transparent !important;
    color: #093764;
    font-size: 24px;
    height: 2em;
    line-height: 1.2em;
    width: auto;
    transition: .5s;
    text-align: left;
    padding-left: 0em !important;
    padding-right: 3em !important;
    position: relative;
    border-radius: unset !important;
  }
  #slide .btn a {
    color: #093764;
    padding: 0px;
  }
  #slide .button::before {
    content: "";
    display: block;
    height: 1px;
    width: 20px;
    background: #093764;
    position: absolute;
    top: 50%;
    right: 1.2em;
    transition: .5s;
  }
  #slide .button::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border: none solid;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 40%;
    right: 1.2em;
    margin-top: 0px;
    transform: rotate(-45deg);
    transition: .5s;
  }
  #slide .button:hover::before {
          right: 12px;
  }
  
  #slide .button:hover::after {
          right: 12px;
  }
  @media screen and (max-width: 768px) {
    #slide img {
      height: 400px !important;
    }
    .carousel-caption {
      background: white;
      position: absolute;
      right: 0%;
      bottom: 50px;
      left: 26%;
      z-index: 10;
      padding: 20px;
      /* padding-bottom: 20px; */
      color: black;
      text-align: left;
  }
  #slide .btn{
    font-size: 18px;
  }
  #slide .button::before {
    top: 52%;
  }
  }