#contact .card {
    border-radius: 0px;
    border: none;
    background-color: #ececec;
}

#contact .card-body {
    padding: 4rem !important;
}

.un {
    text-decoration: underline;
}