#foot .footer-pd {
  padding: 56px 114px;
}
#foot .row {
  margin-right: 0px;
  margin-left: 0px;
}
#foot .navstyle{
  list-style: none;
  color: white !important;
 }
 #foot li a {
   font-size: 16px;
   padding: 0px;
  color: #ffffff;
  text-decoration: none;
}
#foot p a {
  font-size: 20px;
  padding: 0px;
 color: #ffffff;
 text-decoration: none;
}
#foot a:hover {
  color: #ffffffb3;
  text-decoration: none;
}

#footb a {
  font-size: 16px;
  color: white;
  margin: 1rem !important;
}
#foot p {
  font-size: 20px;
}