body {
    font-family: 'Barlow Semi Condensed', sans-serif !important;
    font-size: 20px;
  font-weight: 500;
}
.sticky-top {
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
}
a {
    padding: .5rem 1rem;
    color: black;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #000000b3;
    text-decoration: none;
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(0, 0, 0);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #000000b3;
}
.navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}
@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem;
}
}