body {
  margin: 0;
  font-family: 'Barlow', sans-serif !important;
}

code {
  font-family: 'Barlow', sans-serif !important;
}

p,h1,h2,h3,h4,h5,h6 {
  font-family: 'Barlow', sans-serif !important;
}

p {
    font-weight: normal;
    font-size: 20px;
}
p span{
  font-size: 14px;
}
.ptext{
  font-weight: 500;
  font-size: 24px;
}
h1 {
  font-weight: 900 !important;
    font-size: 64px !important;
}

h2 {
  font-weight: 900 !important;
    font-size: 42px !important;
}
.blue-themetext{
  color: #093764 !important;
}
.blue-themebg{
  background-color : #093764 !important;
}
h3 {
  font-weight: 600 !important;
  font-size: 32px !important;
}

h4 {
  font-weight: 600 !important;
    font-size: 26px !important;
}

h5 {
  font-weight: 500 !important;
    font-size: 24px !important;
}

h6 {
  font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
}
.bg-theme {
  background-color: black !important;
}
.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 2.5 !important;
  background-color: transparent;
  border: none !important;
  position: absolute;
  right: 5%;
  top: 5%;
}
@media screen and (max-width: 768px) {
	p {
    font-weight: normal;
    font-size: 18px;
    }
    p span{
      font-size: 14px;
    }
    .ptext{
      font-weight: 500;
      font-size: 20px;
    }
    h1 {
      font-weight: 900 !important;
        font-size: 48px !important;
    }

    h2 {
      font-weight: 900 !important;
        font-size: 30px !important;
    }
    h3 {
      font-weight: 600 !important;
      font-size: 26px !important;
    }

    h4 {
      font-weight: 600 !important;
        font-size: 22px !important;
    }

    h5 {
      font-weight: 500 !important;
        font-size: 20px !important;
    }
    .button {
      height: auto !important;
    }
}
/* theme button css */
.btn {
  background: black !important;
  border-color: black !important;
  color: #ffffff;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5rem;
  width: auto;
  transition: .5s;
  text-align: center;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  position: relative;
  border-radius: unset !important;
}
.btn a {
  color: #ffffff;
}
.btn a:hover {
  color: #ffffffd1;
}
.button::before {
  content: "";
  display: block;
  height: 1px;
  width: 20px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  right: 1.2em;
  transition: .5s;
}
.button::after {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  border: 1px solid;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: 38%;
  right: 1.2em;
  margin-top: 1px;
  transform: rotate(-45deg);
  transition: .5s;
}
.button:hover::before {
		right: 12px;
}

.button:hover::after {
		right: 12px;
}
/* input box style */
.form-control {
  font-size: 24px !important;
  color: black !important;
  padding: .375rem 0rem !important;
  background-color: #ececec !important;
  border: none !important;
  border-bottom: 3px solid !important;
  border-radius: unset !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.custom-select {
  font-size: 24px !important;
  color: black !important;
  padding: .375rem 0rem !important;
  background-color: #ececec !important;
  border: none !important;
  border-bottom: 3px solid !important;
  border-radius: unset !important;
}
.custom-select:focus {
  box-shadow: none !important;
}
.form-group {
  margin-bottom: 2rem !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f5f5f5 !important;
}